import './../App.css';
import PoolInstance from './PoolInstance'

function Home() {

  return (
    <div className="App">
      <PoolInstance />
    </div>
  );
}

export default Home;
