import { useState, useEffect } from 'react';
import './../App.css';

import { ethers } from 'ethers'
import { Pool } from '@uniswap/v3-sdk'
import { Token } from '@uniswap/sdk-core'
import  IUniswapV3Pool  from '@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json'

const provider  = new ethers.providers.Web3Provider((window as any).ethereum)
const poolAddress = '0x8ad599c3A0ff1De082011EFDDc58f1908eb6e6D8'
const poolContract = new ethers.Contract(poolAddress, IUniswapV3Pool.abi, provider)

interface Immutables {
  factory: string
  token0: string
  token1: string
  fee: number
  tickSpacing: number
  maxLiquidityPerTick: ethers.BigNumber
}

interface State {
  liquidity: ethers.BigNumber
  sqrtPriceX96: ethers.BigNumber
  tick: number
  observationIndex: number
  observationCardinality: number
  observationCardinalityNext: number
  feeProtocol: number
  unlocked: boolean
}

function PoolInstance() {
  const [pool, setPool] = useState<Pool>();

  useEffect(() => {
      // Mounting Tasks
      main().then((data) => {
      setPool(data);
    })
  }, []);

  if (pool !== undefined) {
    return (
      <div >
        <ul>
          <li>
            Pool Fee: { pool.fee }
          </li>
          <li>
            Pool Liquidity: { pool.liquidity.toString() }
          </li>
          <li>
            Current Tick: { pool.tickCurrent }
          </li>
          <li>
            Token_0: <b>{ pool.token0.symbol }</b>
          </li>
          <li>
            Address_0: <a href={"https://etherscan.io/address/" + pool.token0.address}>{ pool.token0.address }</a>
          </li>
          <li>
            Price_0: ${ pool.token0Price.toFixed() }
          </li>
          <li>
            Token_1: <b>{ pool.token1.symbol }</b>
          </li>
          <li>
            Address_1: <a href={"https://etherscan.io/address/" + pool.token1.address}>{ pool.token1.address }</a>
          </li>
          <li>
            Price_1: ${ pool.token1Price.toFixed() }
          </li>
        </ul>
      </div>
    );
  } else {
    return (
      <div>
        Loading ...
      </div>
    )
  }
}

async function getPoolImmutables() {
  const [factory, token0, token1, fee, tickSpacing, maxLiquidityPerTick] = await Promise.all([
    poolContract.factory(),
    poolContract.token0(),
    poolContract.token1(),
    poolContract.fee(),
    poolContract.tickSpacing(),
    poolContract.maxLiquidityPerTick(),
  ])

  const immutables: Immutables = {
    factory,
    token0,
    token1,
    fee,
    tickSpacing,
    maxLiquidityPerTick,
  }
  return immutables
}

async function getPoolState() {
  const [liquidity, slot] = await Promise.all([poolContract.liquidity(), poolContract.slot0()])

  const PoolState: State = {
    liquidity,
    sqrtPriceX96: slot[0],
    tick: slot[1],
    observationIndex: slot[2],
    observationCardinality: slot[3],
    observationCardinalityNext: slot[4],
    feeProtocol: slot[5],
    unlocked: slot[6],
  }

  return PoolState
}

async function main() {
  const [immutables, state] = await Promise.all([getPoolImmutables(), getPoolState()])

  const TokenA = new Token(3, immutables.token0, 6, 'USDC', 'USD Coin')

  const TokenB = new Token(3, immutables.token1, 18, 'WETH', 'Wrapped Ether')

  const poolExample = new Pool(
    TokenA,
    TokenB,
    immutables.fee,
    state.sqrtPriceX96.toString(),
    state.liquidity.toString(),
    state.tick
  )

  return poolExample
}

export default PoolInstance;
